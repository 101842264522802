window.copyToClipboard = function (selector) {
    let element = document.querySelector(selector);

    if (element) {
        let textToCopy = element.innerText;

        if (navigator?.clipboard?.writeText && window.isSecureContext) {
            navigator.clipboard.writeText(textToCopy);
        }
    }
};
