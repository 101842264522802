export class Reorder {
    constructor(productClass, deliveryId, dropdownId, payClass, csrf) {
        const products = document.getElementsByClassName(productClass);
        const delivery = document.getElementById(deliveryId);
        const dropdown = document.getElementById(dropdownId);
        const payButtons = document.getElementsByClassName(payClass);

        if (
            products.length === 0 ||
            payButtons.length === 0 ||
            !delivery ||
            !dropdown
        ) {
            return;
        }

        this.csrf = csrf;
        this.payButtons = payButtons;
        this.payClass = payClass;
        this.products(products);
        this.delivery(delivery, dropdown);
        this.missingDeliveryDay(delivery, payButtons);
        this.checkout(payButtons);
    }

    getData(url, method, body = {}) {
        const options = {
            headers: {
                "Content-Type": "application/json",
                "X-CSRF-Token": this.csrf,
            },
            method: method,
        };

        if (method === "POST") {
            options.body = JSON.stringify(body);
        }

        return fetch(url, options)
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }

                return Promise.reject({});
            })
            .then((data) => {
                return data;
            })
            .catch((data) => data);
    }

    update(data) {
        if (Object.keys(data).length === 0) return;

        for (const [selector, value] of Object.entries(data)) {
            const type = typeof value;
            const el = document.getElementById(selector);

            if (el.classList.contains(this.payClass)) {
                for (const button of this.payButtons) {
                    if (!value) {
                        button.setAttribute("disabled", "disabled");
                    } else {
                        button.removeAttribute("disabled");
                    }
                }

                continue;
            }

            if (type === "boolean" && value) {
                el.classList.remove("hide");
            }

            if (type === "boolean" && !value) {
                el.classList.add("hide");
            }

            if (type === "string") {
                el.innerHTML = value;
            }
        }
    }

    products(elements) {
        for (const el of elements) {
            el.addEventListener("change", (e) => {
                const url = "/reorder/update/products";
                const param = {
                    id: e.target.dataset.productId,
                    state: e.target.checked,
                };

                this.getData(url, "POST", param).then((data) => {
                    this.update(data);
                });
            });
        }
    }

    delivery(el, dropdown) {
        el.addEventListener("change", (e) => {
            const url = "/reorder/update/delivery";
            const param = { day: e.target.value };

            this.getData(url, "POST", param).then((data) => {
                this.update(data);

                dropdown.options[0].setAttribute("disabled", "disabled");

                const el = typeof $(".selectpicker").selectpicker?.();

                if ($ && el === "object") {
                    $(".selectpicker").selectpicker("refresh");
                }
            });
        });
    }

    checkout(payButtons) {
        const url = "/reorder/status/checkout";

        this.getData(url, "GET").then((data) => {
            for (const [selector, value] of Object.entries(data)) {
                const el = document.getElementById(selector);

                if (el.classList.contains(this.payClass) && !value) {
                    for (const button of payButtons) {
                        button.setAttribute("disabled", "disabled");
                    }
                }
            }
        });
    }

    missingDeliveryDay(delivery, payButtons) {
        for (const button of payButtons) {
            button.parentElement.addEventListener("click", () => {
                const url = "/reorder/status/delivery";

                this.getData(url, "GET").then((data) => {
                    this.update(data);
                });
            });
        }
    }
}
